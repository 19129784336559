<template>
  <div class="ml-basket-item">
    <div class="ml-basket-item_image">
      <v-img
        :aspect-ratio="16 / 9"
        :max-height="95"
        :max-width="140"
        class="ml-img-rounded mb-2"
        :src="item.certificate.img"
      >
        <div class="ml-basket-price-label">{{ item.price }}₽</div>
      </v-img>
      <MlNumeric
        class="align-self-center"
        v-model="item.count"
        max="10"
        @input="changeCount"
      />
    </div>
    <div class="ml-basket-item_content">
      <div class="ml-title-14-20 ml-text-grey1 mb-4">
        <span v-if="item.congratulation" class="text-break">
          {{ shortText(item.congratulation) }}
        </span>
        <span v-else class="font-italic">Без поздравления</span>
      </div>
      <div class="d-flex flex-column align-items-start mb-3">
        <div class="ml-text-13-16-500 ml-text-grey4 mb-1">Дата отправки</div>
        <a
          href="#"
          @click.prevent="changeDeliveryDateTime"
          class="ml-silver-btn-sm py-1 px-3 ml-radius-24"
        >
          <span class="ml-text-13-20-500 ml-text-grey1 ml-text-nowrap">
            {{ shortText(deliveryDateTime, 19) }}
          </span>
        </a>
      </div>
      <div class="d-flex flex-column align-items-start">
        <div class="ml-text-13-16-500 ml-text-grey4 mb-1">Получатель</div>
        <a
          href="#"
          @click.prevent="changeRecipient"
          class="ml-silver-btn-sm py-1 px-3 ml-radius-24"
        >
          <span class="ml-text-13-20-500 ml-text-grey1 ml-text-nowrap">
            {{ shortText(deliveryRecipient, 16) }}
          </span>
        </a>
      </div>
    </div>
    <div class="d-flex align-center">
      <a href="#" @click.prevent="edit" v-if="allowEditBasket">
        <img class="btn-go-detail" src="~@/assets/img/arrow-right.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import MlNumeric from '@/components/UI/MlNumeric'
import { mapActions, mapMutations, mapState } from 'vuex'
import basketTypes from '@/store/basket/types'
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
import MixinCommon from '@/helpers/mixins/common'
import { format, parseISO } from 'date-fns'
import SelectTimeDialog from '@/components/Modal/Friend/SelectTime'

export default {
  name: 'TheBasketItem',
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  components: {
    MlNumeric
  },
  mixins: [MixinChangePanelPage, MixinCommon],
  computed: {
    ...mapState({
      allowEditBasket: state => state?.app?.pageBasketConfig?.AllowEdit ?? true
    }),
    deliveryDateTime() {
      const { date, time } = this.item?.friend
      if (!date && (!time || !time?.time)) {
        return 'Сейчас'
      }
      let resultDay
      if (!date) {
        if (time?.time?.value === this.$const.TIME_TO_SEND?.NOW) {
          resultDay = 'Сейчас'
        } else {
          resultDay = 'Сегодня'
        }
      } else {
        resultDay = format(parseISO(date), 'dd.MM.yyyy')
      }
      return `${resultDay}`
    },
    deliveryRecipient() {
      const { name } = this.item?.friend
      if (!name) {
        return 'Вы'
      }
      return name
    }
  },
  methods: {
    ...mapMutations('basket', [basketTypes.SET_CURRENT_CERTIFICATE]),
    ...mapActions('basket', [basketTypes.UPDATE_CERTIFICATE_ACTION]),
    changeRecipient() {
      this.$router.push({
        name: 'FriendContact',
        params: {
          routeAfterSave: 'Basket',
          friend: this.item?.friend,
          basketId: this.item?.id
        }
      })
    },
    changeDeliveryDateTime() {
      this.$router.push({
        name: 'FriendDeliveryTime',
        params: {
          routeAfterSave: 'Basket',
          dateProp: this.item.friend?.date,
          timeProp: this.item.friend?.time,
          basketId: this.item?.id
        }
      })
      // this.$modal.show(
      //   SelectTimeDialog,
      //   {
      //     basketId: this.item?.id,
      //     dateProp: this.item?.friend?.date,
      //     timeProp: this.item?.friend?.time
      //   },
      //   {
      //     ...this.$config.defaultModal,
      //     scrollable: true
      //   }
      //   // {
      //   //   closed: this.closeda
      //   // }
      // )
    },
    // closeda() {
    //   setTimeout(() => {
    //     this.$nextTick(() => {
    //       this.$forceUpdate()
    //     })
    //     console.log('closed')
    //   })
    // },
    changeCount() {
      this[basketTypes.UPDATE_CERTIFICATE_ACTION](this.item)
    },
    edit() {
      this[basketTypes.SET_CURRENT_CERTIFICATE](this.item)
      this.showNavsAndRedirect(false, '/')
      // this.$router.push('/')
    },
    shortText(str, length = 50) {
      if (str && str.length > length) {
        return `${str.substring(0, length)}...`
      }
      return str
    }
  }
}
</script>
