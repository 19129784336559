<template>
  <div class="v-application">
    <div class="v ml-default-modal bg-white w100">
      <img
        src="@/assets/img/close.svg"
        class="ml-btn-close-modal"
        @click="$emit('close')"
        alt=""
      />
      <div class="ml-text-20-32-600 mb-4">
        Время отправки
      </div>
      <div>
        <MlCalendar v-model="date" @select-date="selectDate" class="mb-5" />
      </div>
      <div>
        <MlInputTime
          @select-manual="selectManual"
          :active="isManualTime"
          class="mb-2"
        />
        <div
          class="ml-select-time-item mb-2"
          v-for="(item, index) in times"
          :key="index"
          @click.prevent="selectTime(item)"
          :class="{ active: isActiveTime(item) }"
        >
          <div class="ml-select-time-item_time" v-if="item.time">
            {{ item.time }}
          </div>
          <div class="ml-select-time-item_text">{{ item.text }}</div>
        </div>
      </div>
      <div>
        <button @click.prevent="save" class="ml-black-btn w100 mt-4">
          Применить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/helpers/constants'
import MlCalendar from '@/components/UI/MlCalendar'
import MlInputTime from '@/components/UI/MlInputTime'
import orderTypes from '@/store/order/types'
import { mapActions } from 'vuex'

const timeNow = {
  time: null,
  text: 'Сейчас',
  value: Constants?.TIME_TO_SEND?.NOW
}

const timeAny = {
  time: '00:00 - 23:59',
  text: 'В любое время',
  value: Constants?.TIME_TO_SEND?.ANY
}

export default {
  props: {
    dateProp: String,
    timeProp: Object,
    basketId: String
  },
  components: {
    MlCalendar,
    MlInputTime
  },
  data: () => ({
    date: null,
    time: null,
    // prettier-ignore
    times: [
      timeAny,
      { time: '10:00 - 12:00', text: 'С утра', value: Constants?.TIME_TO_SEND?.MORNING },
      { time: '12:00 - 14:00', text: 'В обед', value: Constants?.TIME_TO_SEND?.LUNCH },
      { time: '18:00 - 20:00', text: 'Вечером', value: Constants?.TIME_TO_SEND?.EVENING },
      // { time: null, text: 'Сейчас', value: Constants?.TIME_TO_SEND?.NOW },
      timeNow
    ]
  }),
  computed: {
    isManualTime() {
      return this.time?.value === Constants?.TIME_TO_SEND?.MANUAL
    }
  },
  mounted() {
    this.setInitialize()
  },
  methods: {
    ...mapActions('order', [orderTypes.SET_FRIEND_DELIVERY_DATETIME_ACTION]),
    setInitialize() {
      this.date = this.dateProp
      this.time = this.timeProp
      if (!this.dateProp && !this.timeProp) {
        this.time = timeNow
      }
    },
    selectManual(value = null) {
      const data = {
        text: 'В указанное время',
        time: null,
        value: Constants?.TIME_TO_SEND?.MANUAL
      }
      if (value) {
        data.time = value
      }
      this.time = data
    },
    isActiveTime(item) {
      return this.time?.value === item?.value
    },
    selectDate(date) {
      this.date = date
      this.time = timeAny
    },
    selectTime(time) {
      if (time?.value === Constants?.TIME_TO_SEND?.NOW) {
        this.date = null
      }
      this.$set(this, 'time', time)
    },
    save() {
      this[orderTypes.SET_FRIEND_DELIVERY_DATETIME_ACTION]({
        date: this.date,
        time: this.time,
        id: this.basketId
      })

      this.$emit('close')
    }
  }
}
</script>
